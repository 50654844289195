import { getRecoil } from 'recoil-nexus';
import { FormDataValue } from '../stores/atom/FormData';
import { SelectedTariffAtom } from '../features/TariffComparison/interfaces';
import ScreenProgress from '../stores/atom/ScreenProgress';
import SelectedAgencyAtom, { SelectedAgency } from '../stores/atom/SelectedAgency';
import SelectedTariff from '../features/TariffComparison/stores/atom/SelectedTariff';
import SummaryAtom from '../stores/atom/SummaryAtom';
import { FileMetaData } from '../components/Upload';

// ToDO: Form validation needs to be refactored..
export function getInvalidFields(
	formFields: Record<string, string>,
	formSpecialCases?: {
		isTariffComparisonManuallyCalculated?: boolean;
		selectedAgency?: SelectedAgency;
		selectedTariff?: SelectedTariffAtom;
	},
): string[] {
	return Object.keys(formFields)?.filter((key) => {
		const element = document.getElementById(key);
		const hasMandatory = element?.hasAttribute('mandatory');
		const getMandatory = element?.getAttribute('mandatory');
		const hasRegex = element?.hasAttribute('regex');
		const regexExpression = element?.getAttribute('regex');

		if ((hasMandatory && getMandatory === '') && (formFields[key]?.length === 0 || formFields[key] === 'false')) return key;
		if (getMandatory === 'true' && (formFields[key].length === 0 || formFields[key] === 'false')) return key;
		if (hasRegex && regexExpression && formFields[key].length > 0) {
			const regexFlags = element?.getAttribute('type') === 'oev-text-area-v1' ? 's' : '';
			const isValidInput = new RegExp(regexExpression, regexFlags).test(formFields[key]);
			return isValidInput ? null : key;
		}

		const isUpload = element?.hasAttribute('accepted-file-types');
		if (isUpload) {
			const uploadedFiles = (element?.getAttribute('default')) as string;
			let uploadedFilesArray: FileMetaData[] = [];

			try {
				uploadedFilesArray = JSON.parse(uploadedFiles) as FileMetaData[];
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error('Failed to parse default attribute as JSON:', e);
			}

			const hasError = element?.classList.contains('error');
			const isEmptyUpload = hasMandatory && uploadedFilesArray.length === 0;
			const isLoading = element?.hasAttribute('is-loading');

			if (hasError || isEmptyUpload || isLoading) {
				return key;
			}
		}
		if (element?.id === 'Agency' && hasMandatory && !formSpecialCases?.selectedAgency?.Agenturnummer) return key;

		if (formSpecialCases?.isTariffComparisonManuallyCalculated === false) return null;
		if (element?.id === 'TariffComparison' && (Object.keys(formSpecialCases?.selectedTariff?.signedTariffOffer ?? {}).length > 0 && Object.keys(formSpecialCases?.selectedTariff ?? {}).length === 0)) return key;

		return null;
	});
}

export function validateForms(form: FormDataValue, isTariffComparisonManuallyCalculated?: boolean) {
	if (!form) return;
	const formFields = { ...form };
	const unfilteredFields = getRecoil(SummaryAtom);
	const currentScreen = getRecoil(ScreenProgress);
	const selectedAgency = getRecoil(SelectedAgencyAtom);
	const selectedTariff = getRecoil(SelectedTariff);
	const unfilteredFieldsOnScreen = Object.values(unfilteredFields)[currentScreen];
	const hasAgency = unfilteredFieldsOnScreen?.includes('Agency');
	const hasTariffComparison = unfilteredFieldsOnScreen?.includes('TariffComparison');

	if (hasAgency) formFields.Agency = selectedAgency.Agenturnummer ? selectedAgency.Agenturnummer : '';
	if (hasTariffComparison) formFields.TariffComparison = selectedTariff.tariffName ? selectedTariff.tariffName : '';

	const invalidFields: string[] = getInvalidFields(
		formFields,
		{
			isTariffComparisonManuallyCalculated,
			selectedAgency,
			selectedTariff,
		},
	);
	// sets validate flag for web components
	// styling is set accordingly -> error messages
	invalidFields.forEach((el) => {
		const formEl = document.getElementById(el || '');
		if (formEl) {
			formEl.setAttribute('validate', 'true');
			if (formEl.getAttribute('type') === 'oev-checkbox') formEl.setAttribute('required', '');
			if (formEl?.id === 'Agency') formEl.querySelector('oev-search-bar')?.setAttribute('validate', '');
		}
	});
	return invalidFields;
}
