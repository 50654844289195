import React, { useEffect, useRef } from 'react';
import '@oev-dxp/oev-components/dist/checkbox';
import '@oev-dxp/oev-components/dist/fieldset';
import '@oev-dxp/oev-components/dist/tooltip';
import { textContentMarkup } from '../../utils';
import { setLabelText } from '../../utils/setLabelText';
import { Event } from './Constants';
import { ChangeEvent, CheckboxProps } from './Interfaces';

function Checkbox({
	checked,
	label = '',
	labelAlignment = 'right',
	legend = '',
	onChange,
	validation = {
		mandatory: {
			choice: '',
			errorMessage: '',
		},
		regex: {
			errorMessage: '',
			expression: '',
		},
	},
	...props
}: Readonly<CheckboxProps>) {
	const isMandatory = validation?.mandatory.choice === 'mandatory';
	const errorMessage = validation?.mandatory.errorMessage ?? 'Fehlende Angabe';
	const checkboxRef = useRef<EventTarget | null>(null);

	useEffect(() => {
		const handleChange = (event: ChangeEvent) => onChange({
			value: event.detail.checked.toString(),
		});

		checkboxRef.current?.addEventListener(Event.change, handleChange);

		return () => {
			checkboxRef.current?.removeEventListener(Event.change, handleChange);
		};
	}, []);

	const legendText = setLabelText(legend, isMandatory);

	return (
		<oev-fieldset
			data-testId="oev-fieldset"
			legend={legendText}
			// eslint-disable-next-line no-underscore-dangle
			tooltip-text={textContentMarkup(props?.tooltip ?? '')?.__html}
		>
			<oev-checkbox
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={textContentMarkup(label ?? '')}
				error={errorMessage}
				label-alignment={labelAlignment}
				ref={checkboxRef}
				type="oev-checkbox"
				{...(props.default === 'true' ? { checked: '' } : null)}
				{...(isMandatory ? { mandatory: '' } : null)}
				{...props}
			/>
		</oev-fieldset>
	);
}

export default Checkbox;
