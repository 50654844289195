import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import AgencyData from '../stores/atom/AgencyData';
import FormAdMandant from '../../../stores/atom/FormAdMandant';
import FormRegion from '../../../stores/atom/FormRegion';
import FormDataContentRoot from '../../../stores/atom/formDataContentRoot';
import SelectedAgencyAtom, { SelectedAgency } from '../../../stores/atom/SelectedAgency';
import ShowSelectedAgencyAtom from '../../../stores/atom/ShowSelectedAgency';
import AgencyCardsAtom from '../../../stores/atom/AgencyCards';
import ScreenContext from '../../../stores/selector/ScreenContextSelector';
import { FormDataValue } from '../../../stores/atom/FormData';
import SubmitData from '../../../stores/selector/SubmitDataSelector';
import { scrollToElementWithOffset, isValidPLZ } from '../../../utils';
import { mapAgencyDataBranchesToCards, mapLocationSearchDataToSearchCards } from '../utils/util';
import { AgencyDataSource, AgencyEvent, ApiErrorState } from '../Constants';
import { searchAgencyByLocation } from '../api/getAgencyByLocation';
import AgencyLocationSearchCard from '../interfaces/AgencyLocationSearchCard';
import AgencyLocationSearch from './AgencyLocationSearch';
import AgencySlider from './AgencySlider';
import AgencyProps from '../interfaces/AgencyProps';
import dataLayerHandler from '../../../utils/tracking/dataLayerHandler';
import { TrackingEvent } from '../../../components/constants';
import TrackingSelector from '../../../stores/selector/TrackingSelector';
import { AgencySearchAction, AgencySearchData } from '../../../utils/tracking/getTrackingObject';
import '@oev-dxp/oev-components/dist/agency-card-list';
import '@oev-dxp/oev-components/dist/agency-card';
import '@oev-dxp/oev-components/dist/button';
import '@oev-dxp/oev-components/dist/image';
import '@oev-dxp/oev-components/dist/opening-hours-status-calculator';
import '@oev-dxp/oev-components/dist/opening-hours-table';

function Agency({
	name, label, sectionId = '', ...props
}: Readonly<AgencyProps>) {
	const {
		agencysearch: agencySearch,
		agencyswitchbuttontext,
		agencyswitchbuttontitle,
	} = props;
	const elementId = 'Agency';
	const isMandatory = agencySearch?.validation?.mandatory?.choice === 'mandatory';
	const agencyData = useRecoilValue(AgencyData);
	const setSelectedAgency = useSetRecoilState(SelectedAgencyAtom);
	const { currentScreen: screen } = useRecoilValue(ScreenContext);
	const adMandant = useRecoilValue(FormAdMandant);
	const agencyRef = useRef<HTMLDivElement | null>(null);
	const [showSelectedAgency, setShowSelectedAgency] = useRecoilState(ShowSelectedAgencyAtom);
	const [searchBarInput, setSearchBarInput] = useState<string>('');
	const [searchBarErrorMessage, setSearchBarErrorMessage] = useState<string>('');
	const [agencyCards, setAgencyCards] = useRecoilState(AgencyCardsAtom);
	const [
		agencyLocationSearchCards,
		setAgencyLocationSearchCards,
	] = useState<Array<AgencyLocationSearchCard>>([]);
	const formDataRegion = useRecoilValue(FormRegion);
	const formDataContentRoot = useRecoilValue(FormDataContentRoot);
	const trackingData = useRecoilValue(TrackingSelector);
	const submitData: FormDataValue = useRecoilValue(SubmitData);

	const {
		Hausnummer, PLZ, Strasse, StrasseHausnummer, Wohnort,
	} = submitData;

	const branches = agencyData?.niederlassungen;

	useEffect(() => {
		setSelectedAgency((prevState) => ({
			...prevState,
			key: name,
			label,
			screen,
			sectionId,
			type: 'oev-agency',
		}));
	}, []);

	useEffect(() => {
		if (!branches) return;
		const branchOffice = branches?.at(0);
		const agencyName = branchOffice?.name ?? '';
		const agencyAddress = branchOffice?.plz ? `${branchOffice?.strasseHausnr}, ${branchOffice?.plz} ${branchOffice?.ort}` : '';
		const value = {
			Agenturadresse: agencyAddress,
			Agenturname: agencyName,
		};

		setSelectedAgency((prevState) => ({
			...prevState,
			Agenturnummer: agencyData?.nummer ?? '',
			value,
		}));

		const cards = mapAgencyDataBranchesToCards(
			branches,
			formDataContentRoot,
			formDataRegion,
			agencyData?.nummer,
			agencyData?.typ ?? '',
			agencyswitchbuttontext,
			agencyswitchbuttontitle,
		);
		setAgencyCards(cards);
	}, [agencyData]);

	const agencyDataLayerHandler = ({
		action, keyword, results, errorDescription, agency, prevAgency,
	}: AgencySearchData) => {
		const agencySearchData: AgencySearchData = {
			action,
			agency,
			errorDescription,
			keyword,
			prevAgency,
			results,
		};
		dataLayerHandler({
			agencySearch: agencySearchData,
			event: TrackingEvent.formularSearch,
			trackingData,
		});
	};

	function changeAgentHandler(
		dataSource: AgencyDataSource.default | AgencyDataSource.search,
		index: number,
	) {
		let agencyAddressTemp = '';
		let agencyNameTemp = '';
		let agencyNumberTemp = '';
		let agencyOrderNumberTemp = 0;
		let branchOfficeTemp;

		if (dataSource === AgencyDataSource.default) setShowSelectedAgency(false);
		if (dataSource === AgencyDataSource.search) {
			if (!agencyLocationSearchCards?.length) return;
			branchOfficeTemp = agencyLocationSearchCards?.at(index) as AgencyLocationSearchCard;
			agencyAddressTemp = `${branchOfficeTemp?.street}, ${branchOfficeTemp?.postalCode} ${branchOfficeTemp?.city}`;
			agencyNumberTemp = branchOfficeTemp?.agn ?? '';
			agencyNameTemp = branchOfficeTemp?.branchName ?? '';
			agencyOrderNumberTemp = branchOfficeTemp?.orderNumber ?? 0;
			setAgencyCards([branchOfficeTemp]);
			setShowSelectedAgency(true);
			scrollToElementWithOffset(elementId);
		}

		let previousAgency: SelectedAgency = {} as SelectedAgency;
		setSelectedAgency((prevState) => {
			previousAgency = prevState;
			return ({
				...prevState,
				Agenturnummer: agencyNumberTemp,
				orderNumber: agencyOrderNumberTemp,
				value: { Agenturadresse: agencyAddressTemp || '', Agenturname: agencyNameTemp },
			});
		});

		// tracking event agency search
		const agencyAction = dataSource === AgencyDataSource.search ? 'pick' : 'delete';
		agencyDataLayerHandler({
			action: AgencySearchAction[agencyAction],
			agency: {
				name: agencyNameTemp,
				number: agencyNumberTemp,
			},
			prevAgency: {
				name: previousAgency ? previousAgency!.value.Agenturname : '',
				number: previousAgency ? previousAgency!.Agenturnummer : '',
			},
		});
	}

	const agencySelectEvent = (ev: CustomEvent) => {
		changeAgentHandler(AgencyDataSource.search, parseInt(ev.detail.value, 10));
	};

	const agencySwitchEvent = () => {
		changeAgentHandler(AgencyDataSource.default, 0);
	};

	const agencySearchInputChange = () => setSearchBarErrorMessage('');

	useEffect(() => {
		agencyRef?.current?.addEventListener(AgencyEvent.agencySelect, agencySelectEvent);
		agencyRef?.current?.addEventListener(AgencyEvent.agencySwitch, agencySwitchEvent);
		agencyRef?.current?.addEventListener(AgencyEvent.searchInputChange, agencySearchInputChange);
		return () => {
			agencyRef?.current?.removeEventListener(AgencyEvent.agencySelect, agencySelectEvent);
			agencyRef?.current?.removeEventListener(AgencyEvent.agencySwitch, agencySwitchEvent);
			agencyRef?.current?.removeEventListener(
				AgencyEvent.searchInputChange,
				agencySearchInputChange,
			);
		};
	}, [agencyData, agencyLocationSearchCards]);

	const agencyLocationResponse = async (input: string, isManualInput: boolean) => {
		let cards: AgencyLocationSearchCard[] = [];
		setSearchBarErrorMessage('');
		const res = await searchAgencyByLocation(adMandant, input);
		/** Only show an error message if the search input comes from the searchBar and not from the
		 * address input fields
		 * Only send tracking event when the search is started from manual searchBar input
		 */
		if (!res) {
			if (isManualInput) {
				setSearchBarErrorMessage('Anfrage fehlgeschlagen');
				// tracking event agency search
				agencyDataLayerHandler({
					action: AgencySearchAction.search,
					errorDescription: 'Anfrage fehlgeschlagen',
					keyword: searchBarInput,
					results: 0,
				});
			}
			setAgencyLocationSearchCards(cards);
			return;
		}

		// Only send tracking event when the search is started from manual searchBar input
		if (isManualInput) {
			// tracking event agency search
			agencyDataLayerHandler({
				action: AgencySearchAction.search,
				errorDescription: res.resultErrorType ?? '',
				keyword: searchBarInput,
				results: res.branches?.length ?? 0,
			});
		}

		if (input !== searchBarInput) res.resultErrorType = ApiErrorState.noError;

		switch (res.resultErrorType) {
			case ApiErrorState.area:
				setSearchBarErrorMessage(agencySearch?.errormessagearea ?? 'Nicht im Geschäftsgebiet');
				break;
			case ApiErrorState.noResult:
				setSearchBarErrorMessage(agencySearch?.errormessagenoresult ?? 'Keine Ergebnisse gefunden');
				break;
			case ApiErrorState.noError:
				cards = mapLocationSearchDataToSearchCards(
					res,
					formDataContentRoot,
					formDataRegion,
					agencySearch?.agencyselectbuttontext,
					agencySearch?.agencyselectbuttontitle,
				);
				break;
			default:
				break;
		}
		setAgencyLocationSearchCards(cards);
	};

	useEffect(() => {
		if (!searchBarInput) return;
		agencyLocationResponse(searchBarInput, true);
	}, [searchBarInput]);

	useEffect(() => {
		if (isValidPLZ(PLZ) || Wohnort?.length >= 2) {
			const addressDetails = {
				Hausnummer,
				PLZ,
				Strasse,
				StrasseHausnummer,
				Wohnort,
			};
			const concatenatedAddressInput = Object.values(addressDetails).filter((value) => value).join(' ');
			if (!concatenatedAddressInput) return;
			agencyLocationResponse(concatenatedAddressInput, false);
		}
	}, [Hausnummer, PLZ, Strasse, StrasseHausnummer, Wohnort]);

	const showAgencySlider = agencyCards.length
		? (
			<AgencySlider
				agencyDataNummer={agencyData?.nummer}
				agencyswitchbuttontext={agencyswitchbuttontext}
				agencyswitchbuttontitle={agencyswitchbuttontitle}
				cards={agencyCards}
				label={label ?? ''}
			/>
		)
		: null;

	return (
		<oev-row-v1 col-width-large="8">
			<oev-column-v1 small-landscape-end="-3" small-landscape-start="3">
				<div
					id={elementId}
					ref={agencyRef}
					{...(isMandatory ? { mandatory: '' } : null)}
				>
					{(agencyData.AD_CONTEXT_IS_AVAILABLE || showSelectedAgency)
						? showAgencySlider : (
							<AgencyLocationSearch
								agencyLocationSearchCards={agencyLocationSearchCards}
								agencySearch={agencySearch}
								searchBarErrorMessage={searchBarErrorMessage}
								setSearchInput={setSearchBarInput}
							/>
						)}
				</div>
			</oev-column-v1>
		</oev-row-v1>
	);
}

export default Agency;
