import { atom } from 'recoil';

export interface UserSessionOverrideData {
	[otherOption: string]: string;
}

const UserSessionOverrideAtom = atom<UserSessionOverrideData>({
	default: {},
	key: 'UserSessionOverride',
});

export default UserSessionOverrideAtom;
