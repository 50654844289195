export const dataAdMandant = 'data-ad-mandant';
export const dataAgn = 'data-agn';
export const dataConfigMandant = 'data-config-mandant';
export const dataContentroot = 'data-contentroot';
export const dataFormid = 'data-formid';
export const dataFormOverride = 'data-form-override';
export const dataMandant = 'data-mandant';
export const dataPageConfig = 'data-page-config';
export const dataProduktnehmer = 'data-produktnehmer';
export const dataRegion = 'data-region';
export const dataTheme = 'data-theme';
export const dataUserSessionOverride = 'data-session-form-override';
