import React from 'react';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
import { LoadingSkeleton } from 'view/LoadingSkeleton';
import initRecoilStateFromSessionStorage from 'utils/formRedirect/initRecoilStateFromSessionStorage';

// OEV web components that aren't stand alone
import '@oev-dxp/oev-components/dist/form-label';
import '@oev-dxp/oev-components/dist/icon';

import App from './App';
import * as constants from './Constants';

// Prevents scripts from failing when a custom element is already defined
customElements.defineclone = Object.assign(Object.create(
	Object.getPrototypeOf(customElements),
).define, customElements);
customElements.define = (name, element) => customElements.get(name)
|| customElements.defineclone(name, element);

// FIXME: This is a workaround to support custom elements from oev-components in tsx
declare global {
	namespace JSX {
		interface IntrinsicElements {
			[elemName: string]: any;
		}
	}
}
const rootHTML = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootHTML);
const initializeState = await initRecoilStateFromSessionStorage();

const getAttribute = (attribute: string) => (rootHTML.hasAttribute(attribute)
	? rootHTML.getAttribute(attribute)
	: null);

root.render(
	<React.StrictMode>
		<RecoilRoot initializeState={initializeState}>
			<RecoilNexus />
			<LoadingSkeleton />
			<App
				adMandant={getAttribute(constants.dataAdMandant)}
				agencyId={getAttribute(constants.dataAgn)}
				configMandant={getAttribute(constants.dataConfigMandant)}
				contentRoot={getAttribute(constants.dataContentroot)}
				formId={getAttribute(constants.dataFormid)}
				formOverride={getAttribute(constants.dataFormOverride)}
				mandant={getAttribute(constants.dataMandant)}
				pageConfig={getAttribute(constants.dataPageConfig)}
				productOwner={getAttribute(constants.dataProduktnehmer)}
				region={getAttribute(constants.dataRegion)}
				theme={getAttribute(constants.dataTheme)} // not implemented yet
				userSessionOverride={getAttribute(constants.dataUserSessionOverride)}
			/>
		</RecoilRoot>
	</React.StrictMode>,
);
